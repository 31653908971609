import React from 'react'

import FAQ from './FAQItem'
import Hero from './Hero'
import Footer from './Footer'

import './glowEffect.css'
import NavBar from './NavBar'

function LandingPage() {
	const chromeWebstoreUrl =
		'https://chromewebstore.google.com/detail/loadlineai/mbmmfldidamcenojonbncbmboibphkbd'

	return (
		<div className="min-h-screen bg-gray-100 flex flex-col">
			<NavBar />

			<Hero chromeWebstoreUrl={chromeWebstoreUrl} />

			<FAQ />

			<Footer />
		</div>
	)
}

export default LandingPage
